.page-add-edit {
  position: relative;

  .images-wrapper {
    display: flex;

    .media-card {
      width: 180px;
    }
  }

  .resources {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-group {
      width: 48%;
    }
  }
}

.about {
  padding-top: 40px;
  .medias-list-wrapper .label-and-action{
    margin-top: 0;
  }
}

.options-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.option-item {
  width: 100%;
  display: flex;

  .option-image {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border: 1px solid white;

    &.error {
      border: 1px solid red;
    }

    .option-icon {
      width: 100%;
      height: 100%;
    }

    .overlay {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s;
      background-color: #fffbfbcc;
      cursor: pointer;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .type-select {

    margin-right: 15px;
    width: 150px;
  }

  .input-group {
    flex-grow: 1;

    label {
      margin-bottom: 5px;
    }
  }

  .delete-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 3px 6px;
    border-radius: 5px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;

    i {
      color: #F24134;
      font-size: 19px;
    }

    &:hover {
      background: #F24134;

      i {
        color: white;
        font-size: 19px;
      }
    }

  }
}
